export const state = () => ({
  newCourseIsLoading: false, // yeni ürün loadingi
  courseListRequested: false, // daha önce ürün verisi çekilmiş mi?
  coursesAreLoading: false, // ürünler listesi loadingi
  courses: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
  page: 1, // sayfa bilgisi
})
export const getters = {
  getCourses: (state) =>
    state.courses
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      )
      .filter((c) => c.page === state.page),
  getCoursesCount: (state) => state.count,
  getCoursesLoading: (state) => state.coursesAreLoading,
}
export const mutations = {
  SET_CLEAR_IT(state) {
    state.courses = []
    state.newCourseIsLoading = false
    state.courseListRequested = false
    state.coursesAreLoading = false
    state.count = 0
  },
  // ürünlerin loadingi
  SET_COURSES_ARE_LOADING(state, payload) {
    state.coursesAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_COURSES(state, payload) {
    if (!state.courseListRequested) state.courses = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.courses = []
      state.courseListRequested = false
    } else state.courseListRequested = true
    state.courses = [...state.courses, ...payload.data]
    state.courses = state.courses.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj.page === o.page)) {
        unique.push(o)
      }
      return unique
    }, [])

    state.count = payload.count
  },
  //  sayfayı set et
  SET_PAGE(state, payload) {
    state.page = payload
  },
}
export const actions = {
  // ürünleri getir
  async GET_COURSES(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    commit('SET_PAGE', page)
    if (
      state.courses.length !== 0 &&
      (search === '') & state.courseListRequested &&
      state.courses.map((course) => course.page).includes(page) &&
      categoryId === -1
    )
      return state.courses.filter((c) => c.page === state.page) // daha önce liste çekilmişse return at.
    commit('SET_COURSES_ARE_LOADING', true)
    const response = await this.$api.get(
      `Courses?page=${page}&searchKey=${encodeURI(
        search
      )}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_COURSES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 1,
          }
        }),
      })
      commit('SET_COURSES_ARE_LOADING', false)
      return response.data
    } else
      commit('SET_COURSES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_COURSES_ARE_LOADING', false)
    return []
  },
}
