import { REZERVATION_REQUEST } from '~/plugins/rezzervation'
export const state = () => ({
  customerPackageListIsLoading: true,
  companyRezzervationInformation: { Id: -1 }, // firmanın rezz tarafında bilgisi.
  rooms: [], // rezervation rooms listesi
  customerPackages: [], // müşteri paketleri
  validPayments: { Iyzico: 0, Paypal: 0, Transfer: [] }, // ödeme yöntemleri.

  newSeanceIsLoading: false, // yeni ürün loadingi
  seanceListRequested: false, // daha önce ürün verisi çekilmiş mi?
  seancesAreLoading: false, // ürünler listesi loadingi
  seances: [], // ürünler listesi
  count: 0, // kaç adet ürün bulunuyor bilgisi.
  page: 1, // sayfa bilgisi
})
export const getters = {
  getSeances: (state) => state.seances.filter((s) => s.page === state.page),
  getValidPayments: (state) => state.validPayments,
  getSeancesCount: (state) => state.count,
  getSeancesLoading: (state) => state.seancesAreLoading,
  getCustomerPackageListIsLoading: (state) =>
    state.customerPackageListIsLoading,
  getRezRooms: (state) => state.rooms,
  getCustomerPackages: (state) => state.customerPackages,
  getCompanyRezzervationInformation: (state) =>
    state.companyRezzervationInformation,
}
export const mutations = {
  SET_CLEAR_IT(state) {
    state.seances = []
    state.rooms = []
    state.customerPackages = []
    state.newSeanceIsLoading = false
    state.seanceListRequested = false
    state.seancesAreLoading = false
    state.count = 0
  },
  // ürünlerin loadingi
  SET_SEANCES_ARE_LOADING(state, payload) {
    state.seancesAreLoading = payload
  },
  // ürünleri projeye ekle
  SET_SEANCES(state, payload) {
    if (!state.seanceListRequested) state.seances = []
    if (payload.search !== '' || payload.categoryId !== -1) {
      state.seances = []
      state.seanceListRequested = false
    } else state.seanceListRequested = true
    state.seances = [...state.seances, ...payload.data]
    state.seances = state.seances.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj.page === o.page)) {
        unique.push(o)
      }
      return unique
    }, [])
    state.count = payload.count
  },
  //  sayfayı set et
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_ROOMS(state, payload) {
    state.rooms = payload
  },
  SET_CUSTOMER_PACKAGES(state, payload) {
    state.customerPackages = payload
  },
  SET_VALID_PAYMENTS(state, payload) {
    state.validPayments = payload
  },
  SET_CUSTOMER_PACKAGES_IS_LOADING(state, payload) {
    state.customerPackageListIsLoading = payload
  },
  // müşterinin sahip olduğu firma paketlerini, firma paketleri ile birleştirir. var olanlar mevcut olarak gösterilir.
  SET_PACKAGES_OF_CUSTOMER_TO_COMPANY_PACKAGES(state, payload) {
    state.customerPackages.forEach((cp) => {
      const p = payload.map((p) => p.CustomerPackageId).indexOf(cp.Id)
      if (p !== -1) {
        cp.isPurchased = true
        cp.remainCount = payload[p].RemainCount
        cp.finishedAt = payload[p].FinishedAt
      }
    })
    state.customerPackageListIsLoading = false
  },
  SET_COMPANY_REZZERVATION_INFORMATION(state, payload) {
    state.companyRezzervationInformation = payload
  },
}
export const actions = {
  async GET_COMPANY_REZZERVATION_INFORMATION({ commit }, payload) {
    const response = await REZERVATION_REQUEST(
      `Companies/ByDomain?domain=${payload}`
    )
    if (response.message === 'OK') {
      commit('SET_COMPANY_REZZERVATION_INFORMATION', response.data)
      return true
    }
    return false
  },
  // ürünleri getir
  async GET_SEANCES(
    { state, commit },
    { page = 1, search = '', limit = 12, categoryId = -1, currency }
  ) {
    commit('SET_PAGE', page)
    if (
      state.seances.length !== 0 &&
      (search === '') & state.seanceListRequested &&
      state.seances.map((seance) => seance.page).includes(page) &&
      categoryId === -1
    )
      return // daha önce liste çekilmişse return at.
    commit('SET_SEANCES_ARE_LOADING', true)
    const response = await this.$api.get(
      `Seances?page=${page}&searchKey=${encodeURI(
        search
      )}&currency=${currency}&limit=${limit}${
        categoryId === -1 ? '' : `&categoryId=${categoryId}`
      }`
    )
    if (response.message === 'OK') {
      commit('SET_SEANCES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: response.data.items.map((d) => {
          // .items gelecek.
          return {
            ...d,
            page,
            type: 4,
          }
        }),
      })
      commit('SET_SEANCES_ARE_LOADING', false)
      return response.data
    } else
      commit('SET_SEANCES', {
        count: response.data.count || 0,
        search,
        categoryId,
        data: [],
      })
    commit('SET_SEANCES_ARE_LOADING', false)
    return []
  },
  async GET_ROOMS({ state, commit }, payload) {
    if (
      state.rooms.length !== 0 ||
      state.companyRezzervationInformation.Id === -1
    )
      return
    const response = await REZERVATION_REQUEST(
      `Rooms/GetAllExternal?companyId=${state.companyRezzervationInformation.Id}&currency=${payload.currency}`
    )
    if (response.message === 'OK') {
      const rooms = response.data
      const prc = String(payload.processes).split(',')
      commit(
        'SET_ROOMS',
        rooms.map((room) => ({
          ...room,
          Processes: room.Processes.filter((pr) => prc.includes(String(pr.Id))),
        }))
      )
    }
  },
  //müşteriye ait paketleri çeker
  async GET_PACKAGES_OF_CUSTOMER({ commit }, payload) {
    commit('SET_CUSTOMER_PACKAGES_IS_LOADING', true)
    const response = await REZERVATION_REQUEST(
      `PackagesOfCustomers/GetAllExternal?customerId=${payload.remoteId}&currency=${payload.currency}&processFilter=${payload.processes}`
    )
    if (response.message === 'OK') {
      commit('SET_PACKAGES_OF_CUSTOMER_TO_COMPANY_PACKAGES', response.data)
    }
    commit('SET_CUSTOMER_PACKAGES_IS_LOADING', false)
  },
  // firmanın müşteri paketlerini çeker.
  async GET_CUSTOMER_PACKAGES({ state, commit }, payload) {
    commit('SET_CUSTOMER_PACKAGES_IS_LOADING', true)
    const response = await REZERVATION_REQUEST(
      `CustomerPackages/GetAllExternal?companyId=${state.companyRezzervationInformation.Id}&currency=${payload.currency}&processFilter=${payload.processes}`
    )
    if (response.message === 'OK') {
      const data = response.data || []
      commit('SET_CUSTOMER_PACKAGES', data)
    } else commit('SET_CUSTOMER_PACKAGES', [])
    commit('SET_CUSTOMER_PACKAGES_IS_LOADING', false)
  },
  // firmada geçerli ödeme yöntemlerini getirir.
  async GET_VALID_PAYMENTS({ commit, state }) {
    const response = await REZERVATION_REQUEST(
      `PaymentMethods/GetAll?companyId=${state.companyRezzervationInformation.Id}`
    )
    if (response.message === 'OK') {
      commit('SET_VALID_PAYMENTS', response.data)
      return { status: true, isLoading: false }
    } else commit('SET_VALID_PAYMENTS', { Iyzico: 0, Paypal: 0, Transfer: [] })
    return { status: true, isLoading: false }
  },
}
