import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c0bcb1e8 = () => interopDefault(import('../pages/all-cookies.vue' /* webpackChunkName: "pages/all-cookies" */))
const _4e7a5e0f = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _20880244 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _53b373be = () => interopDefault(import('../pages/create-seance/index.vue' /* webpackChunkName: "pages/create-seance/index" */))
const _fdc25a8a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _85baa9c2 = () => interopDefault(import('../pages/event-list-of-us.vue' /* webpackChunkName: "pages/event-list-of-us" */))
const _ecaf40a6 = () => interopDefault(import('../pages/external-event-video.vue' /* webpackChunkName: "pages/external-event-video" */))
const _c0f75982 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0c9f72a4 = () => interopDefault(import('../pages/forget-password.vue' /* webpackChunkName: "pages/forget-password" */))
const _4ac675d2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6166f7f6 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _75e24586 = () => interopDefault(import('../pages/heo-admin.vue' /* webpackChunkName: "pages/heo-admin" */))
const _9d3f6106 = () => interopDefault(import('../pages/hizmet-sozlesmesi.vue' /* webpackChunkName: "pages/hizmet-sozlesmesi" */))
const _a2ac4574 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _5a62317f = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _af66ba9c = () => interopDefault(import('../pages/kvkk.vue' /* webpackChunkName: "pages/kvkk" */))
const _5b194cf1 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7bc2494f = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _76432698 = () => interopDefault(import('../pages/page-is-closed.vue' /* webpackChunkName: "pages/page-is-closed" */))
const _5e5936ca = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _2edf9f6e = () => interopDefault(import('../pages/myProfile/index.vue' /* webpackChunkName: "pages/myProfile/index" */))
const _db2503be = () => interopDefault(import('../pages/myProfile/books.vue' /* webpackChunkName: "pages/myProfile/books" */))
const _3b450d32 = () => interopDefault(import('../pages/myProfile/events.vue' /* webpackChunkName: "pages/myProfile/events" */))
const _e6cee92c = () => interopDefault(import('../pages/myProfile/quizzes.vue' /* webpackChunkName: "pages/myProfile/quizzes" */))
const _3069f9a9 = () => interopDefault(import('../pages/myProfile/seances.vue' /* webpackChunkName: "pages/myProfile/seances" */))
const _c679b6f8 = () => interopDefault(import('../pages/myProfile/changePassword.vue' /* webpackChunkName: "pages/myProfile/changePassword" */))
const _1f836722 = () => interopDefault(import('../pages/myProfile/courses.vue' /* webpackChunkName: "pages/myProfile/courses" */))
const _7d9e69e6 = () => interopDefault(import('../pages/products/blogs.vue' /* webpackChunkName: "pages/products/blogs" */))
const _00b097b4 = () => interopDefault(import('../pages/products/books.vue' /* webpackChunkName: "pages/products/books" */))
const _755197cd = () => interopDefault(import('../pages/products/events.vue' /* webpackChunkName: "pages/products/events" */))
const _37eec26e = () => interopDefault(import('../pages/products/seances.vue' /* webpackChunkName: "pages/products/seances" */))
const _1079d598 = () => interopDefault(import('../pages/products/courses.vue' /* webpackChunkName: "pages/products/courses" */))
const _13fbd15e = () => interopDefault(import('../pages/categories/_category.vue' /* webpackChunkName: "pages/categories/_category" */))
const _c2b24924 = () => interopDefault(import('../pages/category/_categoryId.vue' /* webpackChunkName: "pages/category/_categoryId" */))
const _771668a2 = () => interopDefault(import('../pages/get-certificate/_courseId.vue' /* webpackChunkName: "pages/get-certificate/_courseId" */))
const _d47d1916 = () => interopDefault(import('../pages/product/_productId/index.vue' /* webpackChunkName: "pages/product/_productId/index" */))
const _90c2c13c = () => interopDefault(import('../pages/purchase/_productId.vue' /* webpackChunkName: "pages/purchase/_productId" */))
const _269fa28a = () => interopDefault(import('../pages/product/_productId/book/_bookId.vue' /* webpackChunkName: "pages/product/_productId/book/_bookId" */))
const _613897f5 = () => interopDefault(import('../pages/product/_productId/event-item/_eventItemId.vue' /* webpackChunkName: "pages/product/_productId/event-item/_eventItemId" */))
const _6a32b938 = () => interopDefault(import('../pages/product/_productId/video/_videoId.vue' /* webpackChunkName: "pages/product/_productId/video/_videoId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/all-cookies",
    component: _c0bcb1e8,
    name: "all-cookies___tr"
  }, {
    path: "/ara",
    component: _4e7a5e0f,
    name: "search___tr"
  }, {
    path: "/comments",
    component: _20880244,
    name: "comments___tr"
  }, {
    path: "/create-seance",
    component: _53b373be,
    name: "create-seance___tr"
  }, {
    path: "/de",
    component: _fdc25a8a,
    name: "index___de"
  }, {
    path: "/en",
    component: _fdc25a8a,
    name: "index___en"
  }, {
    path: "/etkinlik-listemiz",
    component: _85baa9c2,
    name: "event-list-of-us___tr"
  }, {
    path: "/external-event-video",
    component: _ecaf40a6,
    name: "external-event-video___tr"
  }, {
    path: "/faq",
    component: _c0f75982,
    name: "faq___tr"
  }, {
    path: "/forget-password",
    component: _0c9f72a4,
    name: "forget-password___tr"
  }, {
    path: "/giris-yap",
    component: _4ac675d2,
    name: "login___tr"
  }, {
    path: "/hakkimda",
    component: _6166f7f6,
    name: "about___tr"
  }, {
    path: "/heo-admin",
    component: _75e24586,
    name: "heo-admin___tr"
  }, {
    path: "/hizmet-sozlesmesi",
    component: _9d3f6106,
    name: "hizmet-sozlesmesi___tr"
  }, {
    path: "/kategoriler",
    component: _a2ac4574,
    name: "categories___tr"
  }, {
    path: "/kayit-ol",
    component: _5a62317f,
    name: "signup___tr"
  }, {
    path: "/kg",
    component: _fdc25a8a,
    name: "index___kg"
  }, {
    path: "/kvkk",
    component: _af66ba9c,
    name: "kvkk___tr"
  }, {
    path: "/kz",
    component: _fdc25a8a,
    name: "index___kz"
  }, {
    path: "/logout",
    component: _5b194cf1,
    name: "logout___tr"
  }, {
    path: "/new-password",
    component: _7bc2494f,
    name: "new-password___tr"
  }, {
    path: "/page-is-closed",
    component: _76432698,
    name: "page-is-closed___tr"
  }, {
    path: "/product",
    component: _5e5936ca,
    name: "product___tr"
  }, {
    path: "/profilim",
    component: _2edf9f6e,
    name: "myProfile___tr"
  }, {
    path: "/de/about",
    component: _6166f7f6,
    name: "about___de"
  }, {
    path: "/de/all-cookies",
    component: _c0bcb1e8,
    name: "all-cookies___de"
  }, {
    path: "/de/categories",
    component: _a2ac4574,
    name: "categories___de"
  }, {
    path: "/de/comments",
    component: _20880244,
    name: "comments___de"
  }, {
    path: "/de/create-seance",
    component: _53b373be,
    name: "create-seance___de"
  }, {
    path: "/de/event-list-of-us",
    component: _85baa9c2,
    name: "event-list-of-us___de"
  }, {
    path: "/de/external-event-video",
    component: _ecaf40a6,
    name: "external-event-video___de"
  }, {
    path: "/de/faq",
    component: _c0f75982,
    name: "faq___de"
  }, {
    path: "/de/forget-password",
    component: _0c9f72a4,
    name: "forget-password___de"
  }, {
    path: "/de/heo-admin",
    component: _75e24586,
    name: "heo-admin___de"
  }, {
    path: "/de/hizmet-sozlesmesi",
    component: _9d3f6106,
    name: "hizmet-sozlesmesi___de"
  }, {
    path: "/de/kvkk",
    component: _af66ba9c,
    name: "kvkk___de"
  }, {
    path: "/de/login",
    component: _4ac675d2,
    name: "login___de"
  }, {
    path: "/de/logout",
    component: _5b194cf1,
    name: "logout___de"
  }, {
    path: "/de/my-profile",
    component: _2edf9f6e,
    name: "myProfile___de"
  }, {
    path: "/de/new-password",
    component: _7bc2494f,
    name: "new-password___de"
  }, {
    path: "/de/page-is-closed",
    component: _76432698,
    name: "page-is-closed___de"
  }, {
    path: "/de/product",
    component: _5e5936ca,
    name: "product___de"
  }, {
    path: "/de/search",
    component: _4e7a5e0f,
    name: "search___de"
  }, {
    path: "/de/sign-up",
    component: _5a62317f,
    name: "signup___de"
  }, {
    path: "/en/about",
    component: _6166f7f6,
    name: "about___en"
  }, {
    path: "/en/all-cookies",
    component: _c0bcb1e8,
    name: "all-cookies___en"
  }, {
    path: "/en/categories",
    component: _a2ac4574,
    name: "categories___en"
  }, {
    path: "/en/comments",
    component: _20880244,
    name: "comments___en"
  }, {
    path: "/en/create-seance",
    component: _53b373be,
    name: "create-seance___en"
  }, {
    path: "/en/event-list-of-us",
    component: _85baa9c2,
    name: "event-list-of-us___en"
  }, {
    path: "/en/external-event-video",
    component: _ecaf40a6,
    name: "external-event-video___en"
  }, {
    path: "/en/faq",
    component: _c0f75982,
    name: "faq___en"
  }, {
    path: "/en/forget-password",
    component: _0c9f72a4,
    name: "forget-password___en"
  }, {
    path: "/en/heo-admin",
    component: _75e24586,
    name: "heo-admin___en"
  }, {
    path: "/en/hizmet-sozlesmesi",
    component: _9d3f6106,
    name: "hizmet-sozlesmesi___en"
  }, {
    path: "/en/kvkk",
    component: _af66ba9c,
    name: "kvkk___en"
  }, {
    path: "/en/login",
    component: _4ac675d2,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _5b194cf1,
    name: "logout___en"
  }, {
    path: "/en/my-profile",
    component: _2edf9f6e,
    name: "myProfile___en"
  }, {
    path: "/en/new-password",
    component: _7bc2494f,
    name: "new-password___en"
  }, {
    path: "/en/page-is-closed",
    component: _76432698,
    name: "page-is-closed___en"
  }, {
    path: "/en/product",
    component: _5e5936ca,
    name: "product___en"
  }, {
    path: "/en/search",
    component: _4e7a5e0f,
    name: "search___en"
  }, {
    path: "/en/sign-up",
    component: _5a62317f,
    name: "signup___en"
  }, {
    path: "/kg/about",
    component: _6166f7f6,
    name: "about___kg"
  }, {
    path: "/kg/all-cookies",
    component: _c0bcb1e8,
    name: "all-cookies___kg"
  }, {
    path: "/kg/categories",
    component: _a2ac4574,
    name: "categories___kg"
  }, {
    path: "/kg/comments",
    component: _20880244,
    name: "comments___kg"
  }, {
    path: "/kg/create-seance",
    component: _53b373be,
    name: "create-seance___kg"
  }, {
    path: "/kg/event-list-of-us",
    component: _85baa9c2,
    name: "event-list-of-us___kg"
  }, {
    path: "/kg/external-event-video",
    component: _ecaf40a6,
    name: "external-event-video___kg"
  }, {
    path: "/kg/faq",
    component: _c0f75982,
    name: "faq___kg"
  }, {
    path: "/kg/forget-password",
    component: _0c9f72a4,
    name: "forget-password___kg"
  }, {
    path: "/kg/heo-admin",
    component: _75e24586,
    name: "heo-admin___kg"
  }, {
    path: "/kg/hizmet-sozlesmesi",
    component: _9d3f6106,
    name: "hizmet-sozlesmesi___kg"
  }, {
    path: "/kg/kvkk",
    component: _af66ba9c,
    name: "kvkk___kg"
  }, {
    path: "/kg/login",
    component: _4ac675d2,
    name: "login___kg"
  }, {
    path: "/kg/logout",
    component: _5b194cf1,
    name: "logout___kg"
  }, {
    path: "/kg/my-profile",
    component: _2edf9f6e,
    name: "myProfile___kg"
  }, {
    path: "/kg/new-password",
    component: _7bc2494f,
    name: "new-password___kg"
  }, {
    path: "/kg/page-is-closed",
    component: _76432698,
    name: "page-is-closed___kg"
  }, {
    path: "/kg/product",
    component: _5e5936ca,
    name: "product___kg"
  }, {
    path: "/kg/search",
    component: _4e7a5e0f,
    name: "search___kg"
  }, {
    path: "/kg/sign-up",
    component: _5a62317f,
    name: "signup___kg"
  }, {
    path: "/kz/about",
    component: _6166f7f6,
    name: "about___kz"
  }, {
    path: "/kz/all-cookies",
    component: _c0bcb1e8,
    name: "all-cookies___kz"
  }, {
    path: "/kz/categories",
    component: _a2ac4574,
    name: "categories___kz"
  }, {
    path: "/kz/comments",
    component: _20880244,
    name: "comments___kz"
  }, {
    path: "/kz/create-seance",
    component: _53b373be,
    name: "create-seance___kz"
  }, {
    path: "/kz/event-list-of-us",
    component: _85baa9c2,
    name: "event-list-of-us___kz"
  }, {
    path: "/kz/external-event-video",
    component: _ecaf40a6,
    name: "external-event-video___kz"
  }, {
    path: "/kz/faq",
    component: _c0f75982,
    name: "faq___kz"
  }, {
    path: "/kz/forget-password",
    component: _0c9f72a4,
    name: "forget-password___kz"
  }, {
    path: "/kz/heo-admin",
    component: _75e24586,
    name: "heo-admin___kz"
  }, {
    path: "/kz/hizmet-sozlesmesi",
    component: _9d3f6106,
    name: "hizmet-sozlesmesi___kz"
  }, {
    path: "/kz/kvkk",
    component: _af66ba9c,
    name: "kvkk___kz"
  }, {
    path: "/kz/login",
    component: _4ac675d2,
    name: "login___kz"
  }, {
    path: "/kz/logout",
    component: _5b194cf1,
    name: "logout___kz"
  }, {
    path: "/kz/my-profile",
    component: _2edf9f6e,
    name: "myProfile___kz"
  }, {
    path: "/kz/new-password",
    component: _7bc2494f,
    name: "new-password___kz"
  }, {
    path: "/kz/page-is-closed",
    component: _76432698,
    name: "page-is-closed___kz"
  }, {
    path: "/kz/product",
    component: _5e5936ca,
    name: "product___kz"
  }, {
    path: "/kz/search",
    component: _4e7a5e0f,
    name: "search___kz"
  }, {
    path: "/kz/sign-up",
    component: _5a62317f,
    name: "signup___kz"
  }, {
    path: "/profilim/e-urunlerim",
    component: _db2503be,
    name: "myProfile-books___tr"
  }, {
    path: "/profilim/etkinliklerim",
    component: _3b450d32,
    name: "myProfile-events___tr"
  }, {
    path: "/profilim/quizlerim",
    component: _e6cee92c,
    name: "myProfile-quizzes___tr"
  }, {
    path: "/profilim/seanslarim",
    component: _3069f9a9,
    name: "myProfile-seances___tr"
  }, {
    path: "/profilim/sifremi-degistir",
    component: _c679b6f8,
    name: "myProfile-changePassword___tr"
  }, {
    path: "/profilim/videolu-egitimlerim",
    component: _1f836722,
    name: "myProfile-courses___tr"
  }, {
    path: "/urunler/bloglarim",
    component: _7d9e69e6,
    name: "products-blogs___tr"
  }, {
    path: "/urunler/e-urunlerim",
    component: _00b097b4,
    name: "products-books___tr"
  }, {
    path: "/urunler/etkinliklerim",
    component: _755197cd,
    name: "products-events___tr"
  }, {
    path: "/urunler/seanslarim",
    component: _37eec26e,
    name: "products-seances___tr"
  }, {
    path: "/urunler/videolu-egitimlerim",
    component: _1079d598,
    name: "products-courses___tr"
  }, {
    path: "/de/my-profile/books",
    component: _db2503be,
    name: "myProfile-books___de"
  }, {
    path: "/de/my-profile/change-password",
    component: _c679b6f8,
    name: "myProfile-changePassword___de"
  }, {
    path: "/de/my-profile/courses",
    component: _1f836722,
    name: "myProfile-courses___de"
  }, {
    path: "/de/my-profile/events",
    component: _3b450d32,
    name: "myProfile-events___de"
  }, {
    path: "/de/my-profile/quizzes",
    component: _e6cee92c,
    name: "myProfile-quizzes___de"
  }, {
    path: "/de/my-profile/sessions",
    component: _3069f9a9,
    name: "myProfile-seances___de"
  }, {
    path: "/de/products/blogs",
    component: _7d9e69e6,
    name: "products-blogs___de"
  }, {
    path: "/de/products/books",
    component: _00b097b4,
    name: "products-books___de"
  }, {
    path: "/de/products/courses",
    component: _1079d598,
    name: "products-courses___de"
  }, {
    path: "/de/products/events",
    component: _755197cd,
    name: "products-events___de"
  }, {
    path: "/de/products/sessions",
    component: _37eec26e,
    name: "products-seances___de"
  }, {
    path: "/en/my-profile/books",
    component: _db2503be,
    name: "myProfile-books___en"
  }, {
    path: "/en/my-profile/change-password",
    component: _c679b6f8,
    name: "myProfile-changePassword___en"
  }, {
    path: "/en/my-profile/courses",
    component: _1f836722,
    name: "myProfile-courses___en"
  }, {
    path: "/en/my-profile/events",
    component: _3b450d32,
    name: "myProfile-events___en"
  }, {
    path: "/en/my-profile/quizzes",
    component: _e6cee92c,
    name: "myProfile-quizzes___en"
  }, {
    path: "/en/my-profile/sessions",
    component: _3069f9a9,
    name: "myProfile-seances___en"
  }, {
    path: "/en/products/blogs",
    component: _7d9e69e6,
    name: "products-blogs___en"
  }, {
    path: "/en/products/books",
    component: _00b097b4,
    name: "products-books___en"
  }, {
    path: "/en/products/courses",
    component: _1079d598,
    name: "products-courses___en"
  }, {
    path: "/en/products/events",
    component: _755197cd,
    name: "products-events___en"
  }, {
    path: "/en/products/sessions",
    component: _37eec26e,
    name: "products-seances___en"
  }, {
    path: "/kg/my-profile/books",
    component: _db2503be,
    name: "myProfile-books___kg"
  }, {
    path: "/kg/my-profile/change-password",
    component: _c679b6f8,
    name: "myProfile-changePassword___kg"
  }, {
    path: "/kg/my-profile/courses",
    component: _1f836722,
    name: "myProfile-courses___kg"
  }, {
    path: "/kg/my-profile/events",
    component: _3b450d32,
    name: "myProfile-events___kg"
  }, {
    path: "/kg/my-profile/quizzes",
    component: _e6cee92c,
    name: "myProfile-quizzes___kg"
  }, {
    path: "/kg/my-profile/sessions",
    component: _3069f9a9,
    name: "myProfile-seances___kg"
  }, {
    path: "/kg/products/blogs",
    component: _7d9e69e6,
    name: "products-blogs___kg"
  }, {
    path: "/kg/products/books",
    component: _00b097b4,
    name: "products-books___kg"
  }, {
    path: "/kg/products/courses",
    component: _1079d598,
    name: "products-courses___kg"
  }, {
    path: "/kg/products/events",
    component: _755197cd,
    name: "products-events___kg"
  }, {
    path: "/kg/products/sessions",
    component: _37eec26e,
    name: "products-seances___kg"
  }, {
    path: "/kz/my-profile/books",
    component: _db2503be,
    name: "myProfile-books___kz"
  }, {
    path: "/kz/my-profile/change-password",
    component: _c679b6f8,
    name: "myProfile-changePassword___kz"
  }, {
    path: "/kz/my-profile/courses",
    component: _1f836722,
    name: "myProfile-courses___kz"
  }, {
    path: "/kz/my-profile/events",
    component: _3b450d32,
    name: "myProfile-events___kz"
  }, {
    path: "/kz/my-profile/quizzes",
    component: _e6cee92c,
    name: "myProfile-quizzes___kz"
  }, {
    path: "/kz/my-profile/sessions",
    component: _3069f9a9,
    name: "myProfile-seances___kz"
  }, {
    path: "/kz/products/blogs",
    component: _7d9e69e6,
    name: "products-blogs___kz"
  }, {
    path: "/kz/products/books",
    component: _00b097b4,
    name: "products-books___kz"
  }, {
    path: "/kz/products/courses",
    component: _1079d598,
    name: "products-courses___kz"
  }, {
    path: "/kz/products/events",
    component: _755197cd,
    name: "products-events___kz"
  }, {
    path: "/kz/products/sessions",
    component: _37eec26e,
    name: "products-seances___kz"
  }, {
    path: "/de/categories/:category",
    component: _13fbd15e,
    name: "categories-category___de"
  }, {
    path: "/de/category/:categoryId",
    component: _c2b24924,
    name: "category-categoryId___de"
  }, {
    path: "/de/get-certificate/:courseId?",
    component: _771668a2,
    name: "get-certificate-courseId___de"
  }, {
    path: "/de/product/:productId",
    component: _d47d1916,
    name: "product-productId___de"
  }, {
    path: "/de/purchase/:productId",
    component: _90c2c13c,
    name: "purchase-productId___de"
  }, {
    path: "/en/categories/:category",
    component: _13fbd15e,
    name: "categories-category___en"
  }, {
    path: "/en/category/:categoryId",
    component: _c2b24924,
    name: "category-categoryId___en"
  }, {
    path: "/en/get-certificate/:courseId?",
    component: _771668a2,
    name: "get-certificate-courseId___en"
  }, {
    path: "/en/product/:productId",
    component: _d47d1916,
    name: "product-productId___en"
  }, {
    path: "/en/purchase/:productId",
    component: _90c2c13c,
    name: "purchase-productId___en"
  }, {
    path: "/kg/categories/:category",
    component: _13fbd15e,
    name: "categories-category___kg"
  }, {
    path: "/kg/category/:categoryId",
    component: _c2b24924,
    name: "category-categoryId___kg"
  }, {
    path: "/kg/get-certificate/:courseId?",
    component: _771668a2,
    name: "get-certificate-courseId___kg"
  }, {
    path: "/kg/product/:productId",
    component: _d47d1916,
    name: "product-productId___kg"
  }, {
    path: "/kg/purchase/:productId",
    component: _90c2c13c,
    name: "purchase-productId___kg"
  }, {
    path: "/kz/categories/:category",
    component: _13fbd15e,
    name: "categories-category___kz"
  }, {
    path: "/kz/category/:categoryId",
    component: _c2b24924,
    name: "category-categoryId___kz"
  }, {
    path: "/kz/get-certificate/:courseId?",
    component: _771668a2,
    name: "get-certificate-courseId___kz"
  }, {
    path: "/kz/product/:productId",
    component: _d47d1916,
    name: "product-productId___kz"
  }, {
    path: "/kz/purchase/:productId",
    component: _90c2c13c,
    name: "purchase-productId___kz"
  }, {
    path: "/de/product/:productId/book/:bookId",
    component: _269fa28a,
    name: "product-productId-book-bookId___de"
  }, {
    path: "/de/product/:productId/event-detail/:eventItemId",
    component: _613897f5,
    name: "product-productId-event-item-eventItemId___de"
  }, {
    path: "/de/product/:productId/video/:videoId",
    component: _6a32b938,
    name: "product-productId-video-videoId___de"
  }, {
    path: "/en/product/:productId/book/:bookId",
    component: _269fa28a,
    name: "product-productId-book-bookId___en"
  }, {
    path: "/en/product/:productId/event-detail/:eventItemId",
    component: _613897f5,
    name: "product-productId-event-item-eventItemId___en"
  }, {
    path: "/en/product/:productId/video/:videoId",
    component: _6a32b938,
    name: "product-productId-video-videoId___en"
  }, {
    path: "/kg/product/:productId/book/:bookId",
    component: _269fa28a,
    name: "product-productId-book-bookId___kg"
  }, {
    path: "/kg/product/:productId/event-detail/:eventItemId",
    component: _613897f5,
    name: "product-productId-event-item-eventItemId___kg"
  }, {
    path: "/kg/product/:productId/video/:videoId",
    component: _6a32b938,
    name: "product-productId-video-videoId___kg"
  }, {
    path: "/kz/product/:productId/book/:bookId",
    component: _269fa28a,
    name: "product-productId-book-bookId___kz"
  }, {
    path: "/kz/product/:productId/event-detail/:eventItemId",
    component: _613897f5,
    name: "product-productId-event-item-eventItemId___kz"
  }, {
    path: "/kz/product/:productId/video/:videoId",
    component: _6a32b938,
    name: "product-productId-video-videoId___kz"
  }, {
    path: "/get-certificate/:courseId?",
    component: _771668a2,
    name: "get-certificate-courseId___tr"
  }, {
    path: "/kategori/:categoryId",
    component: _c2b24924,
    name: "category-categoryId___tr"
  }, {
    path: "/kategoriler/:category",
    component: _13fbd15e,
    name: "categories-category___tr"
  }, {
    path: "/satin-al/:productId",
    component: _90c2c13c,
    name: "purchase-productId___tr"
  }, {
    path: "/urun/:productId",
    component: _d47d1916,
    name: "product-productId___tr"
  }, {
    path: "/urun/:productId/e-urun/:bookId",
    component: _269fa28a,
    name: "product-productId-book-bookId___tr"
  }, {
    path: "/urun/:productId/etkinlik-detayi/:eventItemId",
    component: _613897f5,
    name: "product-productId-event-item-eventItemId___tr"
  }, {
    path: "/urun/:productId/video/:videoId",
    component: _6a32b938,
    name: "product-productId-video-videoId___tr"
  }, {
    path: "/",
    component: _fdc25a8a,
    name: "index___tr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
