
import Types from '@/static/database/Types.json'
import NavItems from '@/components/Shared/Header/NavItems.json'
import { mapGetters } from 'vuex'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  data() {
    return {
      types: NavItems[2].sub,
      st: this.$store.state.settings.contents[this.$i18n.locale],
    }
  },
  computed: {
    ...mapGetters({
      getPopularProducts: 'popularProducts/getPopularProducts',
    }),
    showFindUs() {
      return (
        this.$store.state.settings.facebook !== '' ||
        this.$store.state.settings.twitter !== '' ||
        this.$store.state.settings.instagram !== '' ||
        this.$store.state.settings.linkedin !== ''
      )
    },
  },
  methods: {
    convertToSlug,
    type(type) {
      return Types.find((t) => t.type === type).to
    },
    typeName(type) {
      return Types.find((t) => t.type === type).name
    },
  },
}
