export const state = () => ({
  productList: [], // productList list
  productListIsLoading: false, // loading
})
export const getters = {
  getProduct: (state) => {
    return (id) => state.productList.find((c) => c.id === id) || false
  },
}
export const mutations = {
  // kurstaki videoları güncelle
  SET_UPDATE_VIDEO(state, payload) {
    const index = state.productList.findIndex((p) => p.id === payload.courseId)
    if (index !== -1) {
      const sectionIndex = state.productList[index].sections.findIndex(
        (s) => s.id === payload.sectionId
      )
      if (sectionIndex !== -1) {
        const videoIndex = state.productList[index].sections[
          sectionIndex
        ].videos.findIndex((v) => v.id === payload.videoId)
        if (videoIndex !== -1) {
          state.productList[index].sections[sectionIndex].videos[videoIndex] = {
            ...state.productList[index].sections[sectionIndex].videos[
              videoIndex
            ],
            ...payload.video,
          }
        }
      }
    }
  },
  SET_CLEAR_IT(state) {
    state.productList = []
    state.productListIsLoading = false
  },
  // for set product which is get the productList
  SET_PRODUCT_LIST(state, payload) {
    // section içine video arrayinin açılması gerekiyor. açılan arrayin içine sectiona ait videolar gelecek.
    state.productList.push(payload)
  },
  // for loading
  SET_PRODUCT_LIST_IS_LOADING(state, payload) {
    state.productListIsLoading = payload
  },
  SET_REMOVE_EVENT_ITEM(state, payload) {
    const index = state.productList.findIndex(
      (event) => event.id === payload.id
    )
    if (index !== -1)
      state.productList[index].eventItems.splice(payload.index, 1)
  },
  // ürünü bul ve satışı gerçekleştir.
  FIND_PRODUCT_THEN_SET_PURCHASE_TO_IT(state, payload) {
    const productIndex = state.productList.findIndex((p) => p.id === payload)
    if (productIndex !== -1) {
      state.productList[productIndex].isPurchased = 1
      state.productList.splice(productIndex, 1)
    }
  },
}
export const actions = {
  // get productList
  async GET_PRODUCT_TO_LIST({ state, commit }, { id, url }) {
    if (state.productList.find((p) => p.id === id) || url === '') return false
    commit('SET_PRODUCT_LIST_IS_LOADING', true)
    const response = await this.$api.get(url)
    if (response.message === 'OK') {
      commit('SET_PRODUCT_LIST', { ...response.data })
      commit('SET_PRODUCT_LIST_IS_LOADING', false)
      return response.data
    } else this.$router.push('/error')
    commit('SET_PRODUCT_LIST_IS_LOADING', false)
    return {}
  },
}
