export const state = () => ({
  token: '',
  baseUrl: 'https://api.gokce-ozel.eralpsoftware.net',
  customHeaders: {},
  uploadProgress: {
    status: true,
    uploading: false,
    progress: 0,
    total: 0,
    loaded: 0,
  },
  logoutCodes: [401, 403],
})
export const getters = {
  getImageReplacePath: () => '-',
  // getBaseUrl: () => 'https://api.gokceozelacademy.com',
  // getBaseUrl: (state) => state.baseUrl,
  getBaseUrl: () => 'http://93.186.120.196:5011',
  // getBaseUrl: () => 'http://192.168.1.96:5003',
  getDefaultImagePath: () => '/imgs/banner-bg.jpeg',
  getErrorPage: () => '/error',
  getBearerToken: () => true,
  getHeaderAuthorizationKey: () => 'Authorization',
  getUploadingProgress: (state) => state.uploadProgress,
  getToken: (state) => state.token,
  getCustomHeaders: (state) => state.customHeaders,
  getErrorCodes: () => [500, 400, 404, 405, 501, 502],
  getLogoutCodes: (state) => state.logoutCodes,
}
export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload
  },
  SET_UPLOAD_PROGRESS(state, payload) {
    state.uploadProgress = payload
  },
  SET_BASE_URL(state, payload) {
    state.baseUrl = payload //'http://192.168.1.22:5003' //payload
    return
  },
  SET_CUSTOM_HEADERS(state, payload) {
    state.customHeaders = payload
  },
}
export const actions = {
  async REQUEST_IS_FAILED({ state, dispatch }, payload) {
    if (state.logoutCodes.includes(payload.status)) {
      dispatch('user/SET_LOGOUT', '', { root: true })
      await dispatch('user/GET_IP', '', { root: true })
      // uygulamanın kategori bilgisi çekilir.
      await dispatch('categories/GET_CATEGORIES', '', { root: true })
    }
  },
  SEND_BASE_URL({ commit }, payload) {
    commit('SET_BASE_URL', payload)
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(true)
      }, 250)
    })
  },
}
