export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  let options = {
      timeZone: app.store.getters['user/getUserIP'].timezone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
    dateOptions = {
      timeZone: app.store.getters['user/getUserIP'].timezone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZoneName: 'short',
    },
    timeOptions = {
      timeZone: app.store.getters['user/getUserIP'].timezone,
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
    formatter = new Intl.DateTimeFormat(app.i18n.locale, options),
    formatterTime = new Intl.DateTimeFormat(app.i18n.locale, timeOptions),
    formatterDate = new Intl.DateTimeFormat(app.i18n.locale, dateOptions)
  // date format 2023-01-01T20:00:00
  inject('date', {
    format: (date, gmt = '+03:00') => {
      return formatter.format(new Date(date + gmt))
    },
    formatOnlyTime: (date, gmt = '+03:00') => {
      return formatterTime.format(new Date(date + gmt))
    },
    formatOnlyDate: (date, gmt = '+03:00') => {
      return formatterDate.format(new Date(date + gmt))
    },
    formatToParts: (date, gmt = '+03:00') =>
      formatter.formatToParts(new Date(date + gmt)),
    getTimeWithoutOffset: (date, gmt = '+03:00') => {
      const resetOffset = new Date().getTimezoneOffset() * 60 * 1000 // bölgenin offseti
      const offset =
        app.store.getters['user/getUserIP'].offset === 1
          ? 0
          : app.store.getters['user/getUserIP'].offset * 1000
      const d = new Date(date + gmt).getTime()
      const remainOffset = resetOffset + offset // burası 0 çıkmalı kullanıcının offseti ile konumun offseti hesaplanır
      return d - remainOffset
      // 20:00 - (00:00) + (-03:00); = 17:00
      // 17:00 - (-03:00) + (-00:00); = 17:00
    },
    formatWithDuration: (date, duration, gmt = '+03:00') => {
      if (!date) return ''
      const d = new Date(date + gmt).getTime() + duration * 60000
      return formatterTime.format(d)
    },
  })
}
